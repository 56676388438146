import React, {useState, useEffect} from 'react';
import { getMusicLessons } from '../../../helpers/data/teachingData';
import '../../Home/Home.css';

const MusicLessons = () => {
    const [musicLessons, setMusicLessons] = useState([]);  

    useEffect(() => {
        getMusicLessons()
        .then((response) => setMusicLessons(response))
        .catch((err) => console.error(err));
    }, [])
  
    return (
        <>
          {musicLessons.header ? (
          <div className="Home mb-2 fade-in newText">
              <div>
                  {musicLessons.header ? (<h1 className="mt-2">{musicLessons.header}</h1>) : ('')}
                  <div className="aboutMe col-xs-6 newText text-left">
                      <div className={musicLessons.image_location === 'left' ? 'imgContainer left-align' : 'imgContainer right-align' }>
                          {musicLessons.image ? (<img alt={musicLessons.image.alt} className="photo" src={musicLessons.image.url}/>) : ('')}
                      </div>
                      <div className="col mt-2" dangerouslySetInnerHTML={{__html: musicLessons.wysiwyg}}>
                      </div>
                  </div>
              </div>
            </div>
            ) : ('')}
          </>
      );
}

export default MusicLessons;
