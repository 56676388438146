import React, {useState, useEffect} from 'react';
import { getForStudents } from '../../../helpers/data/teachingData';

const ForStudents = () => {
    const [pageData, setPageData] = useState([]);  

    useEffect(() => {
        getForStudents()
        .then((response) => setPageData(response))
        .catch((err) => console.error(err));
    }, [])
  
    return (
        <>
        {pageData.page_header ? (
            <div className="SheetMusicOther fade-in header-container">
                {pageData.page_header ? (<h1>{pageData.page_header}</h1>) : ('')}
                {pageData.page_content ? (<div className="newText text-left" dangerouslySetInnerHTML={{__html: pageData.page_content}}></div>) : ('')}
            </div>)
        : ('')}
        </>
    )
}

export default ForStudents;
