import axios from 'axios'
const wpUrl = 'https://schriscollins.website/wp-json/wp/v2'

const getMusicLessons = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${wpUrl}/pages/315`)
      .then((res) => {
        console.log(res)
        const pageObj = res.data.acf;

        resolve(pageObj)
      })
      .catch((err) => reject(err))
  })

  const getForStudents = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${wpUrl}/pages/319`)
      .then((res) => {
        console.log(res)
        const pageObj = res.data.acf;

        resolve(pageObj)
      })
      .catch((err) => reject(err))
  })

export {
    getMusicLessons,
    getForStudents
}
