import React from 'react'

const PageNotFound = () => {
    return (
        <div className="Home mb-2 fade-in newText">
        <div>
            <h1 className="mt-2">404</h1>
            <h2>Oh no! This page doesn't seem to exist!!</h2>
        </div>
      </div>
    )
}

export default PageNotFound